import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import favicon from '../assets/images/favicon.webp';
import ScrollArrows from '../ScrollArrows';
import '../assets/css/style.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import best_yacht_rental_dubai from'../assets/images/blog_1.webp';
import cheapest_yacht_rental_dubai from'../assets/images/blog_2.webp';
import luxury_yacht_rental_dubai from'../assets/images/blog_3.webp';
import party_yacht_rental_dubai from'../assets/images/blog_4.webp';
import private_yacht_rental_dubai from'../assets/images/blog_5.webp';
import yacht_rental_dubai_marina from'../assets/images/blog_6.webp';
import burjalarab from'../assets/images/bruj.webp';

function Article() {
  return (
    <div>

        <Helmet>

        <title>Party Yacht Rental Dubai - Online Booking</title>
        <meta name="description" content="Experience luxury with our Luxury Yacht Rental Dubai. Enjoy stunning views, exclusive services, and unforgettable moments on the sea. Book your yacht with us for low price booking!"/>
        <meta name="keywords" content="Dubai yacht rental, luxury yacht Dubai, rent yacht Dubai, yacht party Dubai, book dubai yacht, book dubai boat, boat rental dubai, dubai boat ride, dubai marina boat, watersports dubai, dubai waterfun, dubai parasailing, water events dubai, dubai yacht events, yacht rental dubai, boat charter dubai, boat cruises dubai, luxury cruise dubai, yacht rental in dubai, boat trip dubai, sea trip dubai, boat hire dubai, boat hire in dubai, fishing trip dubai, boat rent dubai, boat tour in dubai, yacht charter dubai, dubai sea cruise, private charter dubai, dubai yacht charter, dubai yacht rental, fishing trips dubai, rent boat dubai, boat tour dubai, rent a boat dubai, rent a yacht dubai, sea tour dubai, dubai boat rental, dubai boat rentals, boat rental in dubai, boat hire in dubai, boat rental dubai, boat hire dubai, yacht rental in duabi, boat rentals dubai, yacht charters dubai, boat charter dubai, yacht charters in dubai, rent boat in dubai, rent a boat dubai, rent boat dubai, sea tour dubai, sea trip dubai, yacht charter dubai, yacht charter in dubai, dubai yacht cruise, dubai boat charter, boat hire dubai"/>
        <link rel="canonical" title="" href="https://dubaiyachtevents.com/best_yacht_rental"/>
        <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
         {JSON.stringify({
           "@context": "https://schema.org",
           "@type": "BreadcrumbList",
           "itemListElement": [
             {
               "@type": "ListItem",
               "position": 1,
               "name": "Home",
               "item": "https://dubaiyachtevents.com/"
             },
             {
               "@type": "ListItem",
               "position": 2,
               "name": "articles",
               "item": "https://dubaiyachtevents.com/articles"
             }
           ]
         })}
         </script>

     
        </Helmet>

          <Header/>

          <ScrollArrows />


          <section className="section blog" id="blog" style={{ marginTop: '3%' }}>
        <div className="container">

          <h2 className="h2 section-title">Our Articles</h2>
          <p>We cover the entire Dubai with 100% accurate and real news. Spend some time reading our articles; they could be very interesting and will help you stay in touch with Dubai. We upload one article every week about trending topics in Dubai. Save our website to learn more about Dubai trends</p><br/>

          <ul className="blog-list has-scrollbar" id='blog1'>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/best_yacht_rental">
                    <img src={best_yacht_rental_dubai} alt="Best Yacht Rental Dubai" loading="lazy"
                      className="w-100" /> 
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Yacht Rental</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/best_yacht_rental">Best Yacht Rental Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2022-06-05">June 05, 2022</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="145">145</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/cheapest_yacht_rental">
                    <img src={cheapest_yacht_rental_dubai} alt="Cheapest Yacht Rental Dubai" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Cheapest Yacht</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/cheapest_yacht_rental">Cheapest Yacht Rental Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2020-10-14">October 14, 2020</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="114">114</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/luxury_yacht_rental">
                    <img src={luxury_yacht_rental_dubai} alt="Luxury Yacht Rental Dubai" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Luxury Yacht</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/luxury_yacht_rental">Luxury Yacht Rental Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2023-12-25">December 25, 2023</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="548">548</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/party_yacht_rental">
                    <img src={party_yacht_rental_dubai} alt="Party Yacht Rental Dubai" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Party Yacht</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/party_yacht_rental">Party Yacht Rental Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2021-02-20">February 20, 2021</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="926">926</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/private_yacht_rental">
                    <img src={private_yacht_rental_dubai} alt="Private Yacht Rental Dubai" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Private Yacht</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/private_yacht_rental">Private Yacht Rental Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2022-01-14">January 14, 2022</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="114">114</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/yacht_rental_marina">
                    <img src={yacht_rental_dubai_marina} alt="Yacht Rental Dubai Marina" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Marina Yacht</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/yacht_rental_marina">Yacht Rental Dubai Marina</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2023-09-24">September 24, 2023</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="84">84</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner">

                  <Link title="" to="/things-to-do-in-dubai">
                    <img src={burjalarab} alt="Yacht Rental Dubai Marina" loading="lazy"
                      className="w-100" />
                  </Link>

                  <Link title="" to="/yacht_rental" className="btn card-badge">Dubai Activities</Link>

                </figure>

                <div className="card-content">

                  <h3 className="h3 card-title">
                    <Link title="" to="/things-to-do-in-dubai">Things to do in Dubai</Link>
                  </h3>

                  <div className="card-meta">

                    <div className="publish-date">
                      <ion-icon name="time-outline"></ion-icon>

                      <time datetime="2025-01-06">January 06, 2025</time>
                    </div>

                    <div className="comments">
                      <ion-icon name="chatbubble-ellipses-outline"></ion-icon>

                      <data value="844">844</data>
                    </div>

                  </div>

                </div>

              </div>
            </li>

          </ul>

        </div>
      </section>

      

      <Footer/>

    </div>
  );
}

export default Article;
