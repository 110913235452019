import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from'./assets/images/logo.webp';
import NavbarMenu from './NavbarMenu';

const Header = () => {
  useEffect(() => {
    const header = document.querySelector("[data-header]");

    const handleScroll = () => {
      if (window.scrollY >= 10) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header" data-header>
      <div className="container">
        <div className="overlay" data-overlay></div>

        <Link title="Return to Dubai Yacht Events Homepage" to="/" className="logo">
          <img src={logo} title="logo of dubai yacht events" alt="logo of dubai yacht events" />
        </Link>
    
        <NavbarMenu />

        <div className="header-actions">
          <div className="header-contact">
            <a title="Redirect to mobile dialing page" href="tel:0545619773" className="contact-link">+971545619773</a>
            <span className="contact-time">Mon - Sun: 24 Hours</span>
          </div>

          <a title="Redirect to WhatsApp" href="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20can%20we%20finalize%20our%20deal?" className="btn" aria-labelledby="aria-label-txt">
            <ion-icon name="logo-whatsapp"></ion-icon>
            <span id="aria-label-txt">WhatsApp</span>
          </a>

          <Link to="#" className="btn menu-btn" aria-label="Menu">
            <ion-icon name="menu-outline"></ion-icon>
          </Link>

          <button className="nav-toggle-btn" data-nav-toggle-btn aria-label="Toggle Menu">
            <span style={{ backgroundColor: 'green' }} className="one"></span>
            <span style={{ backgroundColor: 'green' }} className="two"></span>
            <span style={{ backgroundColor: 'green' }} className="three"></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
