import React from 'react';
import './assets/css/style.css';
import { Link } from 'react-router-dom';
import logo from'./assets/images/logo.webp';




function Footer() {
    return (
        <footer className="footer">
        <div className="container" style={{alignItems: 'center'}}>
    
          <div className="footer-top">
    
            <div className="footer-brand">
              <Link to="/" className="logo">
                <img src={logo} alt="logo of dubai yacht events" style={{width: '50%'}}/>
              </Link>
    
              <p className="footer-text">
                Search for a luxury yacht in Dubai among the multiple types of options with different features. Try it once before you leave Dubai
              </p>
            </div>
    
            <ul className="footer-list">
    
              <li>
                <p className="footer-list-title">Company</p>
              </li>
    
              <li>
                <Link to="/about_us" className="footer-link">About us</Link>
              </li>
    
              
    
              <li>
                <Link to="/articles" className="footer-link">Our Articles</Link>
              </li>
    
              <li>
                <Link to="/contact_us" className="footer-link">Contact us</Link>
              </li>
    
            </ul>
    
            <ul className="footer-list">

          <li>
            <p className="footer-list-title">Price List</p>
          </li>

          <li>
            <Link title="Redirect to boat ride dubai price page" to="/boat_ride_price" className="footer-link">Boat Ride Dubai Price</Link>
          </li>

          <li>
            <Link title="Redirect to dubai yacht rental price page" to="/dubai_yacht_prices" className="footer-link">Dubai Yacht Rental Prices</Link>
          </li>

          <li>
            <Link title="Redirect to dubai yacht tour price page" to="/dubai_yacht_tour_price" className="footer-link">Dubai Yacht Tour Price</Link>
          </li>


        </ul>
    
            <ul className="footer-list">
    
              <li>
                <p className="footer-list-title">Dubai Water Ride Spot</p>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Dubai Marina</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Dubai Harbour</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">AIN Dubai</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Jumeriah Beach</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Palm Island</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Atlantics Hotel</Link>
              </li>
    
              <li>
                <Link to="#" className="footer-link">Burj Al Arb</Link>
              </li>
    
    
            </ul>
    
          </div>
    
          <div className="footer-bottom">
    
            <ul className="social-list">

          <li title='facebook link'>
            <Link to="https://m.facebook.com/vcyachtsdubai/" className="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </Link>
          </li>
        
          <li title='instagram link'>
            <Link to="https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==" className="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </Link>
          </li>
        
          <li title='x Link'>
            <Link to="https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09" className="social-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </Link>
          </li>
        
          <li title='youtube link'>
            <Link to="https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ" className="social-link">
              <ion-icon name="logo-youtube"></ion-icon>
            </Link>
          </li>
        
          <li title='pinterest link'>
            <Link to="https://www.pinterest.com/booking0845" className="social-link">
              <ion-icon name="logo-pinterest"></ion-icon>
            </Link>
          </li>
        
          <li title='blogspot link'>
            <Link to="https://boatrentaldubaimarina.blogspot.com/" className="social-link">
              <i className="fab fa-blogger"></i>
            </Link>
          </li>
        
          <li title='gmail link'>
            <Link to="mailto:booking@boatrentaldubai.com" className="social-link">
              <ion-icon name="mail-outline"></ion-icon>
            </Link>
          </li>
        
        </ul>
    
            <p className="copyright">
              &copy; 2024 <Link to="#">Dubai Yacht Events</Link>. All Rights Reserved
            </p>
    
          </div>
    
        </div>
      </footer>
   

);
}

export default Footer;
